import React from 'react';
import get from 'lodash.get';

import {
    Spacing,
    HeroSection,
    SharedStyles,
    Container,
    ListOfImg,
} from '../kenra-storybook/index';

const { StSectionTitle } = SharedStyles;

const APPLICATION_CARD_TYPE = 'Application card';

export default function Applications({ applications }) {
    let cards = applications.filter(
        tool => tool.type === APPLICATION_CARD_TYPE
    );
    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <HeroSection
                    title="Applications"
                    bgImg={`/images/salon-material.png`}
                />
            </Spacing>

            <Spacing>
                <section>
                    <StSectionTitle>
                        <Container>
                            <h2>Application Cards</h2>
                        </Container>
                    </StSectionTitle>
                    <ListOfImg
                        // btnMoreHref="/"
                        items={[
                            ...cards.map(card => ({
                                title: card.title,
                                img: get(card, 'image.localFile.publicURL'),
                                styleWidth50: !!card.wide,
                                href: `/application/${card.title}`,
                            })),
                        ]}
                    />
                </section>
            </Spacing>
        </>
    );
}
