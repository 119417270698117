import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Applications from 'components/Applications';
import SEO from '../components/seo';
import get from 'lodash.get';

export default function ApplicationsPage(props) {
    let {
        data: { allContentfulTool, contentfulPage },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'applications'}
            />
            <Applications applications={allContentfulTool.nodes} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        allContentfulTool(filter: { node_locale: { eq: $locale } }) {
            nodes {
                ...ContentfulToolFields
            }
        }
        contentfulPage(
            title: { eq: "Applications" }
            node_locale: { eq: $locale }
        ) {
            ...ContentfulPageFields
        }
    }
`;
